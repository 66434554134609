var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
    _vm.isVisible ? _vm.$style.active : null,
    _vm.isDropdown ? _vm.$style.selectorDropdown : _vm.$style.selectorInline,
  ]},[(!_vm.isDropdown)?_c('v-icon',{staticClass:"v-icon",attrs:{"small":""}},[_vm._v("mdi-translate")]):_vm._e(),_vm._l((_vm.languages),function(language,index){return _c('li',{key:index,class:[
      _vm.selectedLanguage === language.value.toLowerCase()
        ? _vm.$style.active
        : null,
      _vm.$style.item,
      _vm.isDropdown ? _vm.$style.itemDropdown : _vm.$style.itemInline,
    ],on:{"click":() => _vm.setLanguage(language.value)}},[_vm._v(_vm._s(_vm.$t(`LANGUAGE_${language.name.toUpperCase()}`, language.value)))])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }