import { mapActions, mapState, mapMutations } from 'vuex'

export const generalMethods = mapActions('general', [
  'getCategories',
  'getVatRates',
])

export const generalComputed = {
  ...mapState('general', {
    categories: (state) => state.categories,
    vatRates: (state) => state.vat_rates,
  }),
}

export const generalMutations = mapMutations('general', ['SET_LANGUAGE'])
