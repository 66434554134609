<script>
import { getSavedStateSession } from '@utils/storage'
import { generalMethods, generalMutations } from '@src/state/helpers/general'

export default {
  props: {
    onChangeLanguage: {
      type: Function,
      default: () => null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languages: [
        { name: 'Danish', value: 'da' },
        { name: 'English', value: 'en' },
        { name: 'Norwegian', value: 'no' },
        { name: 'Finnish', value: 'fi' },
        { name: 'Swedish', value: 'sv' },
        { name: 'German', value: 'de' },
      ],
      selectedLanguage: getSavedStateSession('selectedLanguage'),
    }
  },
  beforeUpdate: function () {
    this.selectedLanguage = getSavedStateSession('selectedLanguage')
  },
  methods: {
    ...generalMethods,
    ...generalMutations,
    setLanguage(language) {
      this.SET_LANGUAGE(language) // This will trigger watcher 'language.js'
      this.selectedLanguage = language
      this.onChangeLanguage() // Hides language selector and user dropdown
    },
  },
}
</script>

<template>
  <ul
    :class="[
      isVisible ? $style.active : null,
      isDropdown ? $style.selectorDropdown : $style.selectorInline,
    ]"
  >
    <v-icon v-if="!isDropdown" class="v-icon" small>mdi-translate</v-icon>
    <li
      v-for="(language, index) in languages"
      :key="index"
      :class="[
        selectedLanguage === language.value.toLowerCase()
          ? $style.active
          : null,
        $style.item,
        isDropdown ? $style.itemDropdown : $style.itemInline,
      ]"
      @click="() => setLanguage(language.value)"
      >{{ $t(`LANGUAGE_${language.name.toUpperCase()}`, language.value) }}</li
    >
  </ul>
</template>

<style lang="scss" module>
@import '@design';

.item {
  color: $grey;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover,
  &:focus,
  &.active {
    color: $dark;
  }
}

.selectorDropdown {
  position: absolute;
  top: 50%;
  right: 7px;
  left: 7px;
  visibility: hidden;
  background: #fff;
  box-shadow: 3px 5px 20px 0 rgba(0, 0, 0, 0.07);
  opacity: 0;
  transition: all 0.2s ease-out;

  &.active {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
}

.itemDropdown {
  display: block;
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-indent: 0;
  border-top: 1px solid $light-grey;
}

.selectorInline {
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  text-align: center;
}

.itemInline {
  display: inline-block;
  margin: 0 10px;
  font-size: 11px;

  &:first-of-type {
    margin-left: 15px;
  }
}
</style>
